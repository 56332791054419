import React from 'react';

// UI Components
import { ProductContainer } from '../components/index';

const Product = () => {
  return (
    <>
      <ProductContainer />
    </>
  );
};

export default Product;
