import React from 'react';

// UI Components
import { Auth } from '../components/index';

const Login = () => {
  return (
    <>
      <Auth />
    </>
  );
};

export default Login;
